.oferta {
}

.oferta-text {
    text-align: center;
    margin-left: 10px;
    margin-right: 60px;
    max-width: 800px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.oferta-text-title{
    font-size: x-large;
    font-weight: 600;
}

.oferta-icon {
    color: rgb(58, 96, 108);
    fill: currentColor;
    background-color: #adcce9;
    border-radius: 50%;
    height: 65px;
    width: 65px;
    padding: 15px;
    box-sizing: border-box;
    position: relative;
    left: 40%;
}

.oferta-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.oferta-item {
    text-align: center;
    max-width: 400px;
    margin-left: 30px;
    margin-right: 30px;
}