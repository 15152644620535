.olektorze {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.olektorze-img {
  width: 520px;
  height: 347px;
}

.olektorze__title {
  font-size: 180%;
  font-weight: 600;
  margin-top: 0;
}

.olektorze-text {
  max-width: 380px;
  background: white;
  color: black;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 680px) {
  .olektorze-img {
    width: 320px;
    height: 213px;
  }
}

@media (max-width: 1260px) {
  .olektorze-text {
    max-width: 480px;
  }
}

@media (max-width: 680px) {
  .olektorze-text {
    max-width: 280px;
  }
}