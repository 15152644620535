.test {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
}

.test__question {
    margin-top: 20px;
    margin-bottom: 20px;
}

.test__nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.test__btn {
    margin-top: 30px;
    margin-bottom: 50px;
}