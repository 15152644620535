.regulamin-container {
    max-width: 800px; /* Set a maximum width for better readability */
    margin: 20px auto; /* Center the container and add some margin */
    padding: 20px; /* Add padding for spacing inside the container */
    background-color: #f9f9f9; /* Light background color for contrast */
    border: 1px solid #ddd; /* Subtle border for definition */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
    font-family: 'Arial', sans-serif; /* Font for readability */
    line-height: 1.6; /* Line height for better readability */
    color: #333; /* Dark color for text */
  }
  
  .regulamin-container h1 {
    font-size: 24px; /* Font size for the title */
    margin-bottom: 20px; /* Space below the title */
    text-align: center; /* Center the title */
    color: #444; /* Slightly lighter color for the title */
  }
  
  .regulamin-container ol {
    padding-left: 20px; /* Padding for ordered lists */
  }
  
  .regulamin-container ol ol {
    padding-left: 20px; /* Padding for nested ordered lists */
    margin-top: 10px; /* Add margin above nested lists */
  }
  
  .regulamin-container li {
    margin-bottom: 10px; /* Space between list items */
  }
  
  .regulamin-container strong {
    font-weight: bold; /* Bold for important text */
  }