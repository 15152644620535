.formy-zajec {
}
.formy-zajec__main-item {
    color: rgb(212, 197, 197);
    background: #064681;
}

.formy-zajec-text {
    text-align: center;
}

.formy-zajec__title {
    font-size: large;
}
.formy-zajec-icon {
    color: rgb(58, 96, 108);
    fill: currentColor;
    background-color: #adcce9;
    border-radius: 50%;
    height: 65px;
    width: 65px;
    padding: 15px;
    box-sizing: border-box;
    position: relative;
    left: 40%;
}

.formy-zajec-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.formy-zajec-item {
    text-align: center;
    max-width: 250px;
}