.cennik {
    padding-top: 20px;
    text-align: center;
    word-wrap: break-word;
    box-sizing: border-box;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center; */
}

/* @media (max-width: 680px) {
    .cennik__main-item {
        max-width: 300px;
    }
} */

.cennik__main-item {
    background: #064681;

}
.cennik__container {
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: url('./flag.webp');
    background-repeat: repeat;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.cennik__text {
    color: rgb(212, 197, 197);
    font-size: large;
}
.cennik_cena {
    font-size: x-large;
    font-weight: 600;
}

.cennik__item {
    margin: 10px 10px 10px 10px;
    /* background: #4c7397; */
    /* color: #ffffff; */
    background: white;
    opacity: 0.8;
    color: black;
    height: 120px;
    width: 130px;
}

.cennik__item-text {
    opacity: 1;
}

.cennik__female {
    background-image: url("./female-student.webp");
    background-position: 50% 50%;
    background-size: cover;
    object-fit: cover;
    display: block;
}

.cennik__female-item {
    height: 707px;
    width: 1060px;
}

.cennik__female-item {
    height: 707px;
    width: 1060px;
}

@media(max-width: 1000px) {
    .cennik__female-item {
        height: 307px;
        width: 460px;
    }
}

@media(max-width: 570px) {
    .cennik__female-item {
        height: 197px;
        width: 260px;
    }
}