.wyroznia {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.wyroznia__main-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.wyroznia__text {
    font-family: 'Open Sans', sans-serif;
    color: #4c7397;
    font-size: 1.1rem;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
}

.wyroznia__text-titile{
    font-size: x-large;
    font-weight: 600;
}