.front {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.front__img {
    width: 320px;
    height: 479px;
}
.front__online-section{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.front__communicators {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.front__zoom{
    width: 80px;
    height: 80px;
}
.front__skype{
    width: 60px;
    height: 60px;
}
.front__text-company-name{
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;
    font-family: Oswald, sans-serif;
    font-size: 5rem;
    text-transform: uppercase;
    font-weight: 200;
    line-height: 1.1;
    word-wrap: break-word;
    text-align: center;
    padding: 0;
    box-sizing: border-box;
}

.front__text-ewa{
    font-family: Raleway,sans-serif;
    letter-spacing: 8px;
    font-size: 1.25rem;
    font-weight: 900;
    margin: 6px 41px 0;
    word-wrap: break-word;
    position: relative;
    text-align: center;
    padding: 0;
    box-sizing: border-box;
}

.front-text-haslo-1 {
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 0;
    padding: 0;
    word-wrap: break-word;
    position: relative;
    text-align: center;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.2;
    margin-top: 20px;
    font-family: Raleway,sans-serif;
}