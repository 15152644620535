.kontakt {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
    background: #064d8f;
    color: rgb(212, 197, 197);
}

.kontakt__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.kontakt__question{
    font-size: large;
}

.kontakt__adres {
    width: 213px;
    height: 113px;
}

.kontakt__text {
    margin-right: 20px;
    margin-left: 20px;
}
.kontakt__adress{
    height: 139px;
    width: 267px;
}

.kontakt__mapa {
    margin-top: 20px;
    height: 418px;
    width: 474px;
    margin-bottom: 60px;
}

@media(max-width: 936px) {
    .kontakt__mapa {
        height: 250px;
        width: 283px;
    }
}